<template>
  <div class="h-full">
    <th-datatable
      ref="resourceTable"
      :key="$route.fullPath"
      do-route
      no-meta-check
      operations-width="150"
      resource="voucherSystems"
      route-base="/loyalty/vouchers/external_systems"
      sortable
      :buttons="computedButtons"
      :headers="headers"
      :labels="{ table: { headers: { operations: '' } } }"
      :locale="locale"
      :meta-options="resourceQuery"
      :operations="getOperations()"
      :resource-limit="1000"
      :resource-query="{ query: resourceQuery }"
      :show-operations="true"
      @loading-error="handleLoadingError"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'VoucherSystemsAll',
  metaInfo() {
    return {
      title: this.$t('pages.voucher_systems.form.external_system.label')
    }
  },
  data() {
    return {
      resourceQuery: {
        deleted: false,
        external: true
      },
      headers: [
        {
          field: 'name',
          label: this.$t('common.headers.name.title'),
          minWidth: 120,
          truncate: true,
          fallback: '-'
        },
        {
          // Added the field id, as the real value is too nested, and could easily break
          field: 'id',
          label: this.$t('pages.vouchers.all.headers.expires_at'),
          fallback: this.$t('common.forms.labels.forever'),
          minWidth: 120,
          truncate: true,
          formatter: (row) => {
            if (!row.templates || !row.templates[0]?.attributes?.expires_at)
              return this.$t('common.forms.labels.forever')

            //this is a hack to prevent date round up
            const expiresAtDate = this.$date.getStartOfDay(
              row?.templates[0].attributes.expires_at
            )
            return this.$date.formatDateWithTimezone(
              expiresAtDate,
              this.timezone
            )
          },
          sortType: 'date'
        },
        {
          field: 'count',
          label: this.$t('pages.voucher_systems.all.headers.branch_count'),
          minWidth: 120,
          truncate: true,
          fallback: '-',
          formatter: ({ branches }) => {
            if (branches === null) {
              return this.$t('components.available_in.everywhere')
            }
            if (!branches === undefined || !Array.isArray(branches)) return '––'
            if (branches.length === 0) {
              return this.$t('components.available_in.nowhere')
            }
            return branches.length
          }
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      locale: 'Config/getLocale',
      timezone: 'Config/getTimeZone'
    }),
    buttons() {
      return [
        {
          type: 'create',
          scopes: ['loyalty:voucher_systems:create']
        },
        {
          scopes: ['settings:voucher_systems:create'],
          svgicon: 'th-icon-plus',
          label: this.$t('common.forms.labels.old_flow'),
          clickHandler: () =>
            this.$router.push({ name: 'voucher-systems-new-v1' })
        }
      ]
    },
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    }
  },
  methods: {
    refresh() {
      this.$refs.resourceTable.refresh()
    },
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.single', {
          resource: this.$t('common.resource.voucher_system.singular')
        })
      })
    },
    handleLinkClick(row) {
      return this.$router.push(
        this.$makeFilteredPath('/loyalty/vouchers/manager', { system: row.id })
      )
    },
    getOperations() {
      return {
        link: {
          text: this.$t('pages.voucher_systems.operations.view_vouchers'),
          disable: () => false,
          handleClick: this.handleLinkClick
        }
      }
    }
  }
}
</script>
